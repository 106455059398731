
import {computed, ref} from "vue";
import { execute } from "@/mixins/LoadingMixin";
import {
    IonHeader,
    IonContent,
    IonTitle,
    IonPage,
    IonLabel,
    IonItem,
    IonInput,
    IonIcon, 
    IonModal,
    IonButton,
    IonButtons,
    IonToolbar,
    alertController
} from "@ionic/vue";
import {ellipsisVertical, closeOutline, lockClosed} from "ionicons/icons";
import FixedFooter from '@/components/FixedFooter.vue'
import router from "@/router";
import {presentNativeAlert, presentValidationAlert} from "@/services/Notify";
import {JourneyTemplate} from "@/models/JourneyTemplateModel";
import { formatDate } from '@/utils/DatesUtil';
import {deleteTemplate, getTemplates, duplicateTemplate as duplicate, updateTemplate} from "@/services/TemplateService";
import {useStore} from "vuex";
import {defineComponent} from "@vue/runtime-core";
import {ActionSheetBuilder} from "@/mixins/ActionSheetBuilder";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";

export default defineComponent({
    name: "Templates",
    components: {
        IonHeader,
        IonContent,
        IonTitle,
        IonPage,
        IonLabel,
        IonItem,
        IonInput,
        IonIcon,
        IonModal,
        IonButton,
        IonButtons,
        IonToolbar,
        FixedFooter
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.journeySetTemplates);
        await execute(async () => {
            this.templates = await getTemplates(this.store.getters['app/selectedAppInstanceId']);
        }, this.language.errors.loadingTemplates);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const isDuplicateModalOpen = ref(false);
        const setOpen = (state: boolean) => isDuplicateModalOpen.value = state;
        const templates = ref([] as JourneyTemplate[]);
        const duplicateTemplateName = ref('');
        const templateToDuplicate = ref({} as JourneyTemplate);

        const layoutLabel = (template: JourneyTemplate) => {
            return template.enableCustomContent ? language.value.customContentLabel : language.value.basicContentLabel;
        }

        const goToTemplate = (id: string) => {
            router.push({name: 'templateName', params: {id: id}});
        }

        const openDuplicateModal = (template: JourneyTemplate) => {
            templateToDuplicate.value = Object.assign(templateToDuplicate.value, template);
            setOpen(true);
        }
        
        const presentLockedAlert = (template: JourneyTemplate) => {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: language.value.lockedTemplate,
                message: language.value.lockedTemplateExplanation,
                buttons: [
                    {
                        text: language.value.cancel,
                        role: 'cancel',
                        handler: () => {
                            alertController.dismiss();
                        },
                    },
                    {
                        text: language.value.edit,
                        cssClass: 'alert-button-role-cancel',
                        handler: async () => {
                            template.locked = !template.locked;
                            await updateTemplate(template);
                            goToTemplate(template.id);
                        },
                    },
                    {
                        text: language.value.duplicate,
                        handler: async () => {
                            openDuplicateModal(template);
                        },
                    }
                ]
            });
        }
        
        const tryEdit = (template: JourneyTemplate) => {
            if (template.locked) {
                presentLockedAlert(template);
            } else {
                goToTemplate(template.id);
            }
        }
        
        const duplicateTemplate = async () => {
            if (duplicateTemplateName.value !== '') {
                templateToDuplicate.value.name = duplicateTemplateName.value;
                await execute(async () => {
                    await duplicate(templateToDuplicate.value);
                    templates.value = await getTemplates(store.getters['app/selectedAppInstanceId']);
                    setOpen(false);
                }, language.value.errors.duplicatingTemplate);
            } else {
                presentValidationAlert(language.value.validation.enterName);
            }
        }
        
        const delTemplate = (id: string) => {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: language.value.deleteTemplateQuestion,
                message: language.value.verifyDeleteTemplateQuestion,
                buttons: [
                    {
                        text: language.value.cancel,
                        role: 'cancel',
                        handler: () => {
                            alertController.dismiss();
                        },
                    },
                    {
                        text: language.value.delete,
                        handler: async () => {
                            await alertController.dismiss();
                            await execute(async () => {
                                await deleteTemplate(id);
                                templates.value = await getTemplates(store.getters['app/selectedAppInstanceId']);
                            }, language.value.errors.deletingTemplate);
                        },
                    },
                ]
            });
        }

        const presentOptions = async (template: JourneyTemplate) => {
            const actionSheetBuilder = new ActionSheetBuilder(template.name)
                .addEdit(() => {
                    tryEdit(template);
                })
                .addDuplicate(() => {
                    openDuplicateModal(template);
                })
                .addLockUnlock(!template.locked, () => {
                    template.locked = !template.locked;
                    updateTemplate(template);
                })
                .addDelete(() => delTemplate(template.id));
            const actionSheet = await actionSheetBuilder.create();
            await actionSheet.present();
        }

        return {
            store,
            language,
            templates,
            ellipsisVertical,
            footerButtons: computed(() => 
                new FooterButtonsBuilder()
                .addClearButton(false, () => router.push({ name: 'templateInstructions'}), language.value.addNewTemplate)
                .create()
            ),
            presentOptions,
            isDuplicateModalOpen,
            setOpen,
            closeOutline,
            tryEdit,
            duplicateTemplateName,
            duplicateTemplate,
            lockClosed,
            formatDate,
            layoutLabel
        }
    }
});
