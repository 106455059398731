import {
    lockClosedOutline,
    lockOpenOutline,
    cloudDownloadOutline,
    createOutline,
    duplicateOutline,
    pushOutline,
    trashOutline,
    cloudOfflineOutline,
    cubeOutline,
} from 'ionicons/icons';
import {ActionSheetOptions, ActionSheetButton} from "@ionic/core";
import {actionSheetController} from "@ionic/vue";
import {store} from "@/store/Store";

export class ActionSheetBuilder {
    actionSheetOptions = { mode: "md", cssClass: "custom-action-sheet" } as ActionSheetOptions;
    private language = store.getters['app/language'];
    
    constructor(header: string) {
        this.actionSheetOptions.header = header;
        this.actionSheetOptions.buttons = [] as ActionSheetButton[];
    }
    
    addEdit(handler: Function) {
        this.actionSheetOptions.buttons.push({
            text: this.language.edit,
            role: 'destructive',
            icon: createOutline,
            handler: () => handler()
        });
        return this;
    }
    
    addDuplicate(handler: Function) {
        this.actionSheetOptions.buttons.push({
            text: this.language.duplicate,
            role: 'destructive',
            icon: duplicateOutline,
            handler: () => handler()
        });
        return this;
    }

    addLockUnlock(lock: boolean, handler: Function) {
        this.actionSheetOptions.buttons.push({
            text: lock ? this.language.lock : this.language.unlock,
            role: 'destructive',
            icon: lock ? lockClosedOutline : lockOpenOutline,
            handler: () => handler()
        });
        return this;
    }

    addDownload(encrypted: boolean, handler: Function) {
        this.actionSheetOptions.buttons.push({
            text: encrypted ? this.language.downloadEncrypted: this.language.downloadUnencrypted,
            role: 'destructive',
            icon: cloudDownloadOutline,
            handler: () => handler()
        });
        return this;
    }

    addPublish(handler: Function) {
        this.actionSheetOptions.buttons.push({
            text: this.language.publishRepublish,
            role: 'destructive',
            icon: pushOutline,
            handler: () => handler()
        });
        return this;
    }

    addUnpublish(handler: Function) {
        this.actionSheetOptions.buttons.push({
            text: this.language.unpublish,
            role: 'destructive',
            icon: cloudOfflineOutline,
            handler: () => handler()
        });
        return this;
    }
    
    addDelete(handler: Function) {
        this.actionSheetOptions.buttons.push({
            text: this.language.delete,
            role: 'destructive',
            icon: trashOutline,
            handler: () => handler()
        });
        return this;
    }
    
    addDefault(handler: Function) {
        this.actionSheetOptions.buttons.push({
            text: this.language.editDefaultJourneySet,
            role: 'destructive',
            icon: cubeOutline,
            handler: () => handler()
        });
        return this;
    }
    
    async create() {
        return await actionSheetController.create(this.actionSheetOptions);
    }
}